<template>
  <div class="tc p20">
    <i class="pi pi-table empty-icon"></i>
    <div>{{ text }}</div>
  </div>
</template>
<script>
export default {
  name: 'Empty',
  props: {
    text: {
      type: String,
      default: '当前没有数据',
    },
  },
};
</script>
<style scoped>
.empty-icon {
  font-size: 32px;
  color: #575757;
}
</style>
