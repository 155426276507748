<template>
  <div class="home">
    <div class="clearfix pb20">
      <div class="fl pb5 pr5 pt5">
        <el-input type="text" v-model="documentFilter.keyword" placeholder="请输入文档关键词" clearable
                  @keyup.enter="getDocumentList"></el-input>
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-button
          type="primary"
          @click="getDocumentList">
          检索
        </el-button>
      </div>
      <div class="fr">
        <el-button type="primary" @click="openDocumentDialog">追加</el-button>
      </div>
      <div class="fr mr5">
        <el-button type="primary" @click="openDocumentConfigDialog">文档设置</el-button>
      </div>
      <div class="fr">
        <!--        <el-button type="primary" @click="newMerchant">追加</el-button>-->
      </div>
    </div>
    <div class="home-left">
      <Empty v-if="documentList.length === 0"></Empty>
      <el-table
        :data="documentList"
        class="home-list"
        style="width: 100%"
        @cell-click="cellHandleClick"
        v-else>
        <el-table-column
          label="ID"
          prop="id"
          width="100"
        >
        </el-table-column>
        <el-table-column label="标题">
          <template #default="scope">
            <div class="document-content" v-if="scope.row.title">{{ scope.row.title }}</div>
          </template>
        </el-table-column>
        <el-table-column label="域名">
          <template #default="scope">
            <div class="document-content" v-if="scope.row.url">{{ scope.row.url }}</div>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="scope">
            <el-tag :type="getDocumentTagType(scope.row.status)" v-if="scope.row.status">
              {{ getDocumentStatus(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template #default="scope">
            <el-button
              class="mt5"
              type="primary"
              link
              @click.stop="openDocumentDialog(scope.row)">
              追加
            </el-button>
            <el-button
              class="mt5"
              type="primary"
              link
              @click.stop="reEmbedding(scope.row)">
              重新学习
            </el-button>
            <el-button
              class="mt5"
              type="danger"
              link
              @click.stop="deleteDocument(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="documentConfigDialog" title="文档设置" destroy-on-close draggable align-center>
      <div class="home-right" v-loading="loading">
        <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          label-width="120px"
          class="home-form"
          height="calc(100vh - 300px)"
        >
          <el-form-item
            label="分片大小"
            prop="chunk_size"
          >
            <el-input-number
              v-model="form.chunk_size"
              :min="600"
              :max="3000"
              :precision="0"
              placeholder="800">
            </el-input-number>
          </el-form-item>
          <el-form-item label="停用词" prop="es_stop_words">
            <el-input
              type="textarea"
              rows="10"
              v-model="form.es_stop_words"
              :placeholder=configInfo.es_stop_words
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="documentConfigDialog = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="documentDialog" title="添加Web" destroy-on-close draggable align-center>
      <div class="home-right" v-loading="loading">
        <el-form
          ref="ruleFormRef"
          :model="documentForm"
          :rules="documentRules"
          label-width="120px"
          class="home-form"
          height="calc(100vh - 300px)"
        >
          <el-form-item v-if="documentForm.document_id">
            <div style="margin-top: 20px">
              <el-radio-group v-model="documentLanguage">
                <el-radio-button :label="config.lang" :key="config.lang"
                                 v-for="config in merchantServerLanguages">{{ config.name }}
                </el-radio-button>

              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="URL"
            prop="url"
          >
            <el-input
              rows="20"
              v-model="documentForm.url"
              placeholder="请输入URL">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="documentDialog = false">取消</el-button>
          <el-button type="primary" @click="documentUpload">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="documentPreviewDialog" title="分片预览" width="80%" destroy-on-close align-center
               class="no-padding">

      <div class="home-right" v-loading="loading">
        <Empty v-if="documentPreviewList.length === 0"></Empty>
        <el-table
          :data="documentPreviewList"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 300px)"
          v-else>
          <el-table-column
            label="序号"
            type="index"
            width="60">
          </el-table-column>
          <el-table-column
            prop="text"
            label="文本">
            <template #default="scope">
              <p class="preview-cell">{{ scope.row.text }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="clearfix pt5 pb5">
        </div>
      </template>
    </el-dialog>


    <div class="none">
      <a ref="downloadRef"></a>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    // merchant form
    const documentList = ref([])
    const merchant = computed(() => {
      return store.getters.merchant
    })

    const merchantServerLanguages = computed(() => {
      return merchant.value.server_languages.filter(item => item.is_active)
    })

    const merchantRuleFormRef = ref(null)

    const merchantRules = ref({})
    const getDocumentList = () => {
      loading.value = true
      authApi.getMerchantWebDocument(merchant.value.id, documentFilter).then(res => {
        console.log(res)
        documentList.value = res.data
      }).finally(() => {
        loading.value = false
      })
    }

    const deleteDocument = (item) => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除文档',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        console.log(item.id)
        loading.value = true
        authApi.deleteWebDocument(merchant.value.id, item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          getDocumentList()
        }).finally(() => {
          loading.value = false
        })
      })
    }

    const documentFilter = reactive({
      keyword: '',
    })

    // 文档配置 form
    const formRaw = reactive({
      merchant_id: null,
      chunk_size: null,
      es_stop_words: null,
    })

    const form = reactive(JSON.parse(JSON.stringify(formRaw)))
    const loading = ref(false)
    const rulesRaw = reactive({
      merchant_id: [{ required: true, message: '请选择设施', trigger: 'blur' },]
    })
    const rules = ref({})
    const ruleFormRef = ref(null)

    const documentConfigDialog = ref(false)
    const openDocumentConfigDialog = (item) => {
      const newItem = merchant.value
      Object.keys(formRaw).forEach(key => {
        form[key] = newItem ? newItem[key] : formRaw[key]
      })
      form.merchant_id = newItem.id
      form.chunk_size = newItem.config.chunk_size
      form.es_stop_words = newItem.config.es_stop_words
      documentConfigDialog.value = true
    }
    const submit = () => {
      ruleFormRef.value.validate((valid, fields) => {
        if (valid) {
          loading.value = true
          console.log(form)
          authApi.upload(form.merchant_id, form).then(res => {
            console.log(res)
            ElMessage({
              message: '保存成功',
              type: 'success',
            })
            authApi.getMerchantDetail(form.merchant_id).then(res => {
              store.commit('setMerchant', res.data)
              documentConfigDialog.value = false
            }).finally(() => {
              loading.value = false
            })
          })
        }
      })
    }

    // Web Document form

    const documentForm = reactive({
      url: null,
      document_id: null,
      lang: null,
    })

    const documentRules = reactive({
      url: [
        { required: true, message: '请输入URL', trigger: 'blur' },
        {
          pattern: /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/,
          message: '请输入合法的URL',
          trigger: 'blur'
        },
      ]
    })

    const documentLanguage = ref('en-US')

    const documentDialog = ref(false)
    const openDocumentDialog = (item) => {
      documentForm.document_id = item ? item.id : null
      documentDialog.value = true
    }

    const documentUpload = () => {
      ruleFormRef.value.validate((valid, fields) => {
        if (valid) {
          loading.value = true
          console.log(documentForm)
          if (documentForm.document_id) {
            documentForm.lang = documentLanguage.value
            authApi.webDocumentUpdate(merchant.value.id, documentForm.document_id, documentForm).then(res => {
              console.log(res)
              ElMessage({
                message: '添加成功',
                type: 'success',
              })
              documentDialog.value = false
              getDocumentList()
            }).finally(() => {
              loading.value = false
            })
          } else {
            authApi.webDocumentUpload(merchant.value.id, documentForm).then(res => {
              console.log(res)
              ElMessage({
                message: '添加成功',
                type: 'success',
              })
              documentDialog.value = false
              getDocumentList()
            }).finally(() => {
              loading.value = false
            })
          }
        }
      })
    }
    const downloadRef = ref(null)
    const download = (res) => {
      let fileName = ''
      const disposition = res.headers['content-disposition']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        // 正規表現
        const filenameRegex = /filename[^;=\n]=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          // matches[1]でとれる⇒ filename*=utf-8''201911%E3%83%87%E3%83%BC%E3%82%BF.csv;
          // 不要文字列を消して、デコードしてサーバからのファイル名を取得
          fileName = decodeURI(
            matches[1].replace(/['"]/g, '').replace('utf-8', '')
          )
        }
      }
      const fileURL = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/octet-stream' })
      )
      console.log(fileURL)
      downloadRef.value.href = fileURL
      downloadRef.value.setAttribute('download', fileName)
      downloadRef.value.click()
      window.URL.revokeObjectURL(fileURL)
    }

    const configInfo = computed(() => {
      return store.getters.config
    })
    const activeName = ref('cn')

    const handleClick = (tab, event) => {
      console.log(tab, event)
    }
    // dataset preview
    const documentPreviewDialog = ref(false)
    const documentPreviewList = ref([])
    const documentPreview = () => {
      loading.value = true
      const data = JSON.parse(JSON.stringify(form))
      let fullField = 'content'
      if (activeName.value !== 'cn') {
        fullField = activeName.value + '_content'
        data.content = data['multilingual_data'][fullField]
      } else {
        data.content = data[fullField]
      }

      if (data.content === null || data.content === '' || data.content === undefined) {
        ElMessage({
          message: '请输入文档',
          type: 'error',
        })
        loading.value = false
        return
      }
      authApi.uploadPreview(data).then(res => {
        documentPreviewList.value = res.data
        documentPreviewDialog.value = true
      }).finally(() => {
        loading.value = false
      })
    }

    const cellHandleClick = (row, column, event, cell) => {
      console.log(row, column, event, cell)
      router.replace({
        name: 'MerchantWebDocumentDetail',
        params: {
          id: row.id,
        }
      })
    }

    const reEmbedding = (item) => {
      ElMessageBox.confirm(
        '确定要重新学习吗?',
        '重新学习',
        {
          confirmButtonText: '重新学习',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        loading.value = true
        authApi.webDocumentReEmbedding(merchant.value.id, item.id).then(res => {
          console.log(res)
          ElMessage({
            message: '成功',
            type: 'success',
          })
          getDocumentList()
        }).finally(() => {
          loading.value = false
        })
      })
    }

    const getDocumentStatus = (status) => {
      let statusDict = {
        '00': '排队中',
        '10': '抓取中',
        '20': '学习中',
        '30': '已学习',
      }
      return statusDict[status] || '未知'
    }
    const getDocumentTagType = (status) => {
      let statusDict = {
        '00': 'warning',
        '10': 'info',
        '20': 'primary',
        '30': 'success',
      }
      return statusDict[status] || 'warning'
    }

    onMounted(() => {
      getDocumentList()
    })
    return {
      form,
      loading,
      rules,
      ruleFormRef,
      submit,
      documentList,
      merchant,
      merchantServerLanguages,
      merchantRuleFormRef,
      merchantRules,
      deleteDocument,
      documentDialog,
      openDocumentDialog,
      downloadRef,
      documentFilter,
      getDocumentList,
      configInfo,

      documentForm,
      documentLanguage,
      documentRules,
      documentUpload,

      documentPreviewDialog,
      documentPreviewList,
      documentPreview,
      activeName,
      handleClick,

      documentConfigDialog,
      openDocumentConfigDialog,

      cellHandleClick,
      reEmbedding,
      getDocumentStatus,
      getDocumentTagType,
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.document-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
